import React from "react";
import styled, { keyframes } from "styled-components";

const Loader = ({ showLoader }) => {
  return showLoader ? (
    <div className="w-full h-[100vh] fixed top-0 bg-transparent backdrop-blur-[1px] z-50 flex justify-center items-center">
      <div class="loader"></div>
    </div>
  ) : null;
};

export default Loader;
