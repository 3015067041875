import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
function AuthRoutes() {
  const { token, user } = useSelector((state) => state.auth);

  const redirectTo = (role_id) => {
    switch (parseInt(role_id)) {
      case 1:
        return "/manager/accounts";
      default:
        return "/login";
    }
  };

  const getRedirectPath = () => {
    if (!token) return "/login";
    return redirectTo(user?.role_id);
  };
  return !token ? <Outlet /> : <Navigate to={getRedirectPath()} />;
}

export default AuthRoutes;
