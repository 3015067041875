import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validSupergitHIS from "../../api/validSupergitHIS";
import toast from "react-hot-toast";

export const getAccounts = createAsyncThunk(
  "getAccounts",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("listAccounts", payload);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "Failed to get accounts");
      return rejectWithValue(
        error?.response?.data?.error || "Failed to get accounts"
      );
    }
  }
);

export const getListAccounts = createAsyncThunk(
  "getListAccounts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("getAccounts");
      return response.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.error || "Failed to get list accounts"
      );
      return rejectWithValue(
        error?.response?.data?.error || "Failed to get list accounts"
      );
    }
  }
);
export const getCashBankAccounts = createAsyncThunk(
  "getCashBankAccounts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("getCashBankAccounts");
      return response.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.error || "Failed to get cash/bank accounts"
      );
      return rejectWithValue(
        error?.response?.data?.error || "Failed to get cash/bank accounts"
      );
    }
  }
);
// export const getTypesDropdown = createAsyncThunk(
//   "getTypesDropdown",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await validSupergitHIS.post("getCoaType");
//       return response.data;
//     } catch (error) {
//       toast.error(error?.response?.data?.error || "Failed to get types ");
//       return rejectWithValue(
//         error?.response?.data?.error || "Failed to get types"
//       );
//     }
//   }
// );

export const getTypesDropdown = createAsyncThunk(
  "getTypesDropdown",
  async (_, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("getCoaType");
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "Failed to get types");
      return rejectWithValue(
        error?.response?.data?.error || "Failed to get types"
      );
    }
  }
);

// export const getSubTypesDropdown = createAsyncThunk(
//   "getSubTypesDropdown",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await validSupergitHIS.post("getCoaSubTypeByType");
//       return response.data;
//     } catch (error) {
//       toast.error(error?.response?.data?.error || "Failed to get sub types ");
//       return rejectWithValue(
//         error?.response?.data?.error || "Failed to get sub types"
//       );
//     }
//   }
// );
export const getSubTypesDropdown = createAsyncThunk(
  "getSubTypesDropdown",
  async (typeId, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("getCoaSubTypeByType", {
        type_id: typeId,
      });
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "Failed to get sub types ");
      return rejectWithValue(
        error?.response?.data?.error || "Failed to get sub types"
      );
    }
  }
);

export const createAccounts = createAsyncThunk(
  "createAccounts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("account", data);
      toast.success("Account created successfully");
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "Failed to create account");
      return rejectWithValue(
        error?.response?.data?.error || "Failed to create account"
      );
    }
  }
);

export const getExpenseAccounts = createAsyncThunk(
  "getExpenseAccounts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("getExpenseAccounts");
      return response.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.error || "Failed to get list expense accounts"
      );
      return rejectWithValue(
        error?.response?.data?.error || "Failed to get list expense accounts"
      );
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "deleteAccount",
  async (id, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.delete(`delAccounts/${id}`);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error);
      return rejectWithValue(error?.response?.data?.error);
    }
  }
);
export const updateAccount = createAsyncThunk(
  "updateAccount",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(`updateAccount/${id}`, data);
      return response.data;
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || "An unexpected error occurred";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const initialState = {
  isLoading: false,
  accounts: [],
  listAccounts: [],
  page: 0,
  per_page: 0,
  total_pages: 0,
  total_record: 0,
  coaTypes: [],
  coaTypesBySubTypes: [],
  expenseAccounts: [],
  cashBankAccounts: [],
};

const AccountSlice = createSlice({
  name: "accounts",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAccounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.accounts = action.payload.data;
        state.page = action.payload.page;
        state.per_page = action.payload.per_page;
        state.total_pages = action.payload.total_pages;
        state.total_record = action.payload.total_record;
      })
      .addCase(getAccounts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getListAccounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listAccounts = action.payload;
      })
      .addCase(getListAccounts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createAccounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAccounts.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createAccounts.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getTypesDropdown.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTypesDropdown.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coaTypes = action.payload;
      })
      .addCase(getTypesDropdown.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getSubTypesDropdown.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubTypesDropdown.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coaTypesBySubTypes = action?.payload?.data;
      })
      .addCase(getSubTypesDropdown.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getExpenseAccounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getExpenseAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.expenseAccounts = action.payload;
      })
      .addCase(getExpenseAccounts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCashBankAccounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCashBankAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cashBankAccounts = action.payload;
      })
      .addCase(getCashBankAccounts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        toast?.success(action?.payload?.message);
        // state.cashBankAccounts = action.payload;
      })
      .addCase(deleteAccount.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        toast?.success(action?.payload?.message);
        // state.cashBankAccounts = action.payload;
      })
      .addCase(updateAccount.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default AccountSlice.reducer;

// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import validSupergitHIS from "../../api/validSupergitHIS";
// import toast from "react-hot-toast";

// export const getAccounts = createAsyncThunk(
//   "getAccounts",
//   async (payload, { rejectWithValue }) => {
//     try {
//       const response = await validSupergitHIS.post("listAccounts", payload);
//       return response.data;
//     } catch (error) {
//       toast.error(error?.response?.data?.error || "Failed to get accounts");
//       return rejectWithValue(
//         error?.response?.data?.error || "Failed to get accounts"
//       );
//     }
//   }
// );

// export const getListAccounts = createAsyncThunk(
//   "getListAccounts",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await validSupergitHIS.post("getAccounts");
//       return response.data;
//     } catch (error) {
//       toast.error(
//         error?.response?.data?.error || "Failed to get list accounts"
//       );
//       return rejectWithValue(
//         error?.response?.data?.error || "Failed to get list accounts"
//       );
//     }
//   }
// );
// export const getCashBankAccounts = createAsyncThunk(
//   "getCashBankAccounts",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await validSupergitHIS.post("getCashBankAccounts");
//       return response.data;
//     } catch (error) {
//       toast.error(
//         error?.response?.data?.error || "Failed to get cash/bank accounts"
//       );
//       return rejectWithValue(
//         error?.response?.data?.error || "Failed to get cash/bank accounts"
//       );
//     }
//   }
// );
// // export const getTypesDropdown = createAsyncThunk(
// //   "getTypesDropdown",
// //   async (_, { rejectWithValue }) => {
// //     try {
// //       const response = await validSupergitHIS.post("getCoaType");
// //       return response.data;
// //     } catch (error) {
// //       toast.error(error?.response?.data?.error || "Failed to get types ");
// //       return rejectWithValue(
// //         error?.response?.data?.error || "Failed to get types"
// //       );
// //     }
// //   }
// // );

// export const getTypesDropdown = createAsyncThunk(
//   "getTypesDropdown",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await validSupergitHIS.post("getCoaType");
//       return response.data;
//     } catch (error) {
//       toast.error(error?.response?.data?.error || "Failed to get types");
//       return rejectWithValue(
//         error?.response?.data?.error || "Failed to get types"
//       );
//     }
//   }
// );

// // export const getSubTypesDropdown = createAsyncThunk(
// //   "getSubTypesDropdown",
// //   async (_, { rejectWithValue }) => {
// //     try {
// //       const response = await validSupergitHIS.post("getCoaSubTypeByType");
// //       return response.data;
// //     } catch (error) {
// //       toast.error(error?.response?.data?.error || "Failed to get sub types ");
// //       return rejectWithValue(
// //         error?.response?.data?.error || "Failed to get sub types"
// //       );
// //     }
// //   }
// // );
// export const getSubTypesDropdown = createAsyncThunk(
//   "getSubTypesDropdown",
//   async (typeId, { rejectWithValue }) => {
//     try {
//       const response = await validSupergitHIS.post("getCoaSubTypeByType", {
//         type_id: typeId,
//       });
//       return response.data;
//     } catch (error) {
//       toast.error(error?.response?.data?.error || "Failed to get sub types ");
//       return rejectWithValue(
//         error?.response?.data?.error || "Failed to get sub types"
//       );
//     }
//   }
// );

// export const createAccounts = createAsyncThunk(
//   "createAccounts",
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await validSupergitHIS.post("account", data);
//       toast.success("Account created successfully");
//       return response.data;
//     } catch (error) {
//       toast.error(error?.response?.data?.error || "Failed to create account");
//       return rejectWithValue(
//         error?.response?.data?.error || "Failed to create account"
//       );
//     }
//   }
// );

// export const getExpenseAccounts = createAsyncThunk(
//   "getExpenseAccounts",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await validSupergitHIS.post("getExpenseAccounts");
//       return response.data;
//     } catch (error) {
//       toast.error(
//         error?.response?.data?.error || "Failed to get list expense accounts"
//       );
//       return rejectWithValue(
//         error?.response?.data?.error || "Failed to get list expense accounts"
//       );
//     }
//   }
// );

// const initialState = {
//   isLoading: false,
//   accounts: [],
//   listAccounts: [],
//   page: 0,
//   per_page: 0,
//   total_pages: 0,
//   total_record: 0,
//   coaTypes: [],
//   coaTypesBySubTypes: [],
//   expenseAccounts: [],
//   cashBankAccounts: [],
// };

// const AccountSlice = createSlice({
//   name: "accounts",
//   initialState,
//   extraReducers: (builder) => {
//     builder
//       .addCase(getAccounts.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(getAccounts.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.accounts = action.payload.data;
//         state.page = action.payload.page;
//         state.per_page = action.payload.per_page;
//         state.total_pages = action.payload.total_pages;
//         state.total_record = action.payload.total_record;
//       })
//       .addCase(getAccounts.rejected, (state) => {
//         state.isLoading = false;
//       })
//       .addCase(getListAccounts.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(getListAccounts.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.listAccounts = action.payload;
//       })
//       .addCase(getListAccounts.rejected, (state) => {
//         state.isLoading = false;
//       })
//       .addCase(createAccounts.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(createAccounts.fulfilled, (state) => {
//         state.isLoading = false;
//       })
//       .addCase(createAccounts.rejected, (state) => {
//         state.isLoading = false;
//       })

//       .addCase(getTypesDropdown.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(getTypesDropdown.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.coaTypes = action.payload;
//       })
//       .addCase(getTypesDropdown.rejected, (state) => {
//         state.isLoading = false;
//       })

//       .addCase(getSubTypesDropdown.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(getSubTypesDropdown.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.coaTypesBySubTypes = action?.payload?.data;
//       })
//       .addCase(getSubTypesDropdown.rejected, (state) => {
//         state.isLoading = false;
//       })

//       .addCase(getExpenseAccounts.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(getExpenseAccounts.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.expenseAccounts = action.payload;
//       })
//       .addCase(getExpenseAccounts.rejected, (state) => {
//         state.isLoading = false;
//       })
//       .addCase(getCashBankAccounts.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(getCashBankAccounts.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.cashBankAccounts = action.payload;
//       })
//       .addCase(getCashBankAccounts.rejected, (state) => {
//         state.isLoading = false;
//       });
//   },
// });

// export default AccountSlice.reducer;
