import React from "react";
import Navbar from "./Navbar";

export default function RootLayout({ children }) {
  return (
    <div>
      <Navbar />
      <div className="m-4">{children}</div>
    </div>
  );
}
