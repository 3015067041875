import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validSupergitHIS from "../../api/validSupergitHIS";
import toast from "react-hot-toast";

export const GetBusinesses = createAsyncThunk(
  "getBusiness",
  async (_, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("getCompanies");
      return response?.data?.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const GetBusinessesList = createAsyncThunk(
  "GetBusinessesList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("listCompanies", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const CreateBusiness = createAsyncThunk(
  "createBusiness",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("company", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getBranchList = createAsyncThunk(
  "getBranchList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("listBranches", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const createNewBranch = createAsyncThunk(
  "createNewBranch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("branches", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getBranches = createAsyncThunk(
  "getBranches",
  async (_, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("getBranches");
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const deleteBuisness = createAsyncThunk(
  "deleteBuisness",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(`delCompany/${payload?.id}`);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const editBuisness = createAsyncThunk(
  "editBuisness",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        `updateCompany/${payload?.id}`,
        payload?.data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const deleteBranch = createAsyncThunk(
  "deleteBranches",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        `delBranches/${payload?.id}`
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const editBranch = createAsyncThunk(
  "editBranch",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        `updateBranches/${payload?.id}`,
        payload?.data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
const initialState = {
  business: [],
  isLoading: false,
  businessList: [],
  branchList: [],
  branches: {},
};

const BusinessSlice = createSlice({
  name: "business",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(CreateBusiness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateBusiness.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(CreateBusiness.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(GetBusinesses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetBusinesses.fulfilled, (state, action) => {
        state.business = action.payload;
        state.isLoading = false;
      })
      .addCase(GetBusinesses.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(GetBusinessesList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetBusinessesList.fulfilled, (state, action) => {
        state.businessList = action.payload;
        state.isLoading = false;
      })
      .addCase(GetBusinessesList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getBranchList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBranchList.fulfilled, (state, action) => {
        state.branchList = action.payload;
        state.isLoading = false;
      })
      .addCase(getBranchList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createNewBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewBranch.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createNewBranch.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getBranches.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBranches.fulfilled, (state, action) => {
        state.branches = action.payload;
        state.isLoading = false;
      })
      .addCase(getBranches.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editBuisness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editBuisness.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.message || "Succesfully Edit");
      })
      .addCase(editBuisness.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteBuisness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBuisness.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.message || "Succesfully delete");
      })
      .addCase(deleteBuisness.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editBranch.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.message || "Succesfully Edit");
      })
      .addCase(editBranch.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBranch.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.message || "Succesfully delete");
      })
      .addCase(deleteBranch.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default BusinessSlice.reducer;
