import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validSupergitHIS from "../../api/validSupergitHIS";
import toast from "react-hot-toast";

export const getPayslipList = createAsyncThunk(
  "getPayslipList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("payslips/list", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const CreateEmployeePayslip = createAsyncThunk(
  "CreateEmployeePayslip",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("payslips", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getPayrunList = createAsyncThunk(
  "getPayrunList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("listPayrolls", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const CreateEmployeePayrun = createAsyncThunk(
  "CreateEmployeePayrun",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("payrun", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const createNewPayroll = createAsyncThunk(
  "createNewPayroll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("payrolls", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

const initialState = {
  isLoading: false,
  payslipList: null,
  payrunList: null,
};

const PayslipSlice = createSlice({
  name: "payslip",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPayslipList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPayslipList.fulfilled, (state, action) => {
        state.payslipList = action.payload;
        state.isLoading = false;
      })
      .addCase(getPayslipList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getPayrunList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPayrunList.fulfilled, (state, action) => {
        state.payrunList = action.payload;
        state.isLoading = false;
      })
      .addCase(getPayrunList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(CreateEmployeePayslip.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateEmployeePayslip.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(CreateEmployeePayslip.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(CreateEmployeePayrun.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateEmployeePayrun.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(CreateEmployeePayrun.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createNewPayroll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewPayroll.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createNewPayroll.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default PayslipSlice.reducer;
