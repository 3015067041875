import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { PrimeReactProvider } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';
import { ThemeSwitcher } from './ThemeSwitcher/ThemeSwitcher';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toaster } from 'react-hot-toast';
import ErrorBoundary from './components/Shared/ErrorBoundary';
import ErrorBoundaryAlert from './components/Shared/ErrorBoundaryAlert';
import Loader from './Loader';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'fr', 'ar'],
    fallbackLng: 'en',
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie']
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json'
    },
    react: { useSuspense: false }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));

const loadingMarkup = (
  <div
    className='flex justify-center items-center z-50 w-full h-full fixed top-0 left-0'
    style={{ background: 'rgba(0, 0, 0, 0.5);' }}
  >
    <ProgressSpinner />
  </div>
);

root.render(
  <ErrorBoundary fallback={ErrorBoundaryAlert}>
    <Suspense fallback={<Loader showLoader={true} />}>
      {/* <React.StrictMode> */}
      <PrimeReactProvider value={{ unstyled: true, pt: Tailwind }}>
        <App />
        <Toaster
          position='bottom-center'
          reverseOrder={false}
          gutter={8}
          containerClassName=''
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: '',
            duration: 5000,
            style: {
              background: '#363636',
              color: '#fff'
            },

            // Default options for specific types
            success: {
              duration: 3000,
              theme: {
                primary: 'green',
                secondary: 'black'
              }
            }
          }}
        />
      </PrimeReactProvider>
      {/* </React.StrictMode> */}
    </Suspense>
  </ErrorBoundary>
);
