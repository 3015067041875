import React, { lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './scss/index.scss';
import 'react-tooltip/dist/react-tooltip.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import AuthRoutes from './ProtectedRoutes/AuthRoutes';
import ManagerRoutes from './ProtectedRoutes/ManagerRoutes';
import RootLayout from './layouts/RootLayout/RootLayout';
import { useTranslation } from 'react-i18next';

const Home = lazy(() => import('./components/Home'));
const Login = lazy(() => import('./Pages/Auth/Login'));
const Register = lazy(() => import('./Pages/Auth/Register'));
const AccountsMain = lazy(() => import('./Pages/Accounts/AccountsMain'));
const ItemsMain = lazy(() => import('./Pages/Items/ItemsMain'));
const InvoicesMain = lazy(() => import('./Pages/Invoice/InvoicesMain'));
const CompanyInvoicesMain = lazy(() => import('./Pages/CompanyInvoice/CompanyInvoicesMain'));
const TransactionsMain = lazy(() => import('./Pages/Transactions/TransactionsMain'));
const CustomerMain = lazy(() => import('./Pages/Customer/CustomerMain'));
const UsersMain = lazy(() => import('./Pages/User/UsersMain'));
const ReportMain = lazy(() => import('./Pages/Report/ReportMain'));
const ShowInvoice = lazy(() => import('./Pages/Invoice/ShowInvoice'));
const MainBusiness = lazy(() => import('../src/business/MainBusiness'));
const ShowCompanyInvoice = lazy(() => import('./Pages/CompanyInvoice/ShowCompanyInvoice'));
const PayslipMain = lazy(() => import('./Pages/Payslip/PayslipMain'));
const PayrollMain = lazy(() => import('./Pages/Payroll/PayrollMain'));
const VendorMain = lazy(() => import('./Pages/Vendor/VendorMain'));
const PurchasedEntryMain = lazy(() => import('./Pages/PurchasedEntry/PurchasedEntryMain'));
const CreateInvoicePage = lazy(() => import('./Pages/Invoice/CreateInvoicePage'));
const CreateCompanyInvoicePage = lazy(() => import('./Pages/CompanyInvoice/CreateCompanyInvoicePage'));
const CreateEmployeePage = lazy(() => import('./Pages/Employee/CreateEmployeePage'));
const BranchMain = lazy(() => import('./Pages/Branch/BranchMain'));
const CostCenterMain = lazy(() => import('./Pages/CostCenter/CostCenterMain'));
const CreateTransactionPage = lazy(() => import('./Pages/Transactions/CreateTransactionPage'));
const CreatePurchasedEntryPage = lazy(() => import('./Pages/PurchasedEntry/CreatePurchasedEntryPage'));
const CreditTable = lazy(() => import('./CreditInvoice/CreditTable'));
const CreditCompanyInvoice = lazy(() => import('./CreditInvoice/CrdeitCompanyTable'));
const PaymentVoucher = lazy(() => import('./Pages/Voucher/PaymentVoucher'));
const ReceiptVoucher = lazy(() => import('./Pages/Voucher/ReceiptVoucher'));
const CreatePaymentVoucher = lazy(() => import('./Pages/Voucher/CreatePaymentVoucher'));
const CreateReceiptVoucher = lazy(() => import('./Pages/Voucher/CreateReceiptVoucher'));
const ExcelTransactionPage = lazy(() => import('./Pages/Transactions/ExcelTransactionPage'));
const Settings = lazy(() => import('./Pages/settings/Settings'));

// import LeaveMain from "./Pages/Leave/LeaveMain";
// import AttendanceMain from "./Pages/Attendance/AttendanceMain";
// import DeductionMain from "./Pages/Deduction/DeductionMain";
// import AllowanceMain from "./Pages/Allowance/AllowanceMain";
// import EmployeeMain from "./Pages/Employee/EmployeeMain";

function App() {
  const { i18n } = useTranslation();

  // Set the direction based on the language
  useEffect(() => {
    const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', direction);
    document.body.setAttribute('dir', direction);
  }, [i18n.language]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            <Route element={<AuthRoutes />}>
              <Route path='/login' exact element={<Login />} />
              <Route path='/register' exact element={<Register />} />
            </Route>

            <Route
              path='/manager'
              element={
                <RootLayout>
                  <ManagerRoutes />
                </RootLayout>
              }
            >
              <Route path='dashboard' exact element={<Home />} />
              <Route path='settings' exact element={<Settings />} />
              <Route path='user' exact element={<UsersMain />} />
              <Route path='accounts' exact element={<AccountsMain />} />
              <Route path='transactions' exact element={<TransactionsMain />} />
              <Route path='transactions/create' exact element={<ExcelTransactionPage />} />
              {/* <Route
                path="transactions/create"
                exact
                element={<CreateTransactionPage />}
              /> */}
              <Route path='transactions/edit' exact element={<CreateTransactionPage />} />
              <Route path='invoices' exact element={<InvoicesMain />} />
              <Route path='invoices/create' exact element={<CreateInvoicePage />} />
              <Route path='customers' exact element={<CustomerMain />} />
              {/* <Route path="payslip" exact element={<PayslipMain />} /> */}
              <Route path='insuranceInvoice' exact element={<CompanyInvoicesMain />} />
              <Route path='creditInvoiceTable' exact element={<CreditTable />} />
              <Route path='creditCompanyInvoiceTable' exact element={<CreditCompanyInvoice />} />
              <Route path='insuranceInvoice/create' exact element={<CreateCompanyInvoicePage />} />
              <Route path='items' exact element={<ItemsMain />} />
              <Route path='report' exact element={<ReportMain />} />
              <Route path='show-invoice' exact element={<ShowInvoice />} />
              <Route path='show-company-invoice' exact element={<ShowCompanyInvoice />} />
              <Route path='business' exact element={<MainBusiness />} />
              <Route path='branch' exact element={<BranchMain />} />
              <Route path='costCenter' exact element={<CostCenterMain />} />
              <Route path='report' exact element={<ReportMain />} />

              {/* <Route path="employees" exact element={<EmployeeMain />} />
              <Route path="leave" exact element={<LeaveMain />} />
              <Route path="attendance" exact element={<AttendanceMain />} />
              <Route path="deduction" exact element={<DeductionMain />} />
              <Route path="allowance" exact element={<AllowanceMain />} /> */}

              <Route path='payroll' exact element={<PayrollMain />} />
              <Route path='payroll/create' exact element={<CreateEmployeePage />} />
              <Route path='vendor' exact element={<VendorMain />} />
              <Route path='purchased_entry' exact element={<PurchasedEntryMain />} />
              <Route path='purchased_entry/create' exact element={<CreatePurchasedEntryPage />} />
              <Route path='receipt/voucher' exact element={<ReceiptVoucher />} />
              <Route path='create/payment/voucher' exact element={<CreatePaymentVoucher />} />
              <Route path='create/receipt/voucher' exact element={<CreateReceiptVoucher />} />
              <Route path='payment/voucher' exact element={<PaymentVoucher />} />
            </Route>

            <Route path='/' element={<Navigate to='/login' />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
