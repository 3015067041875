import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import validSupergitHIS from "../../api/validSupergitHIS";

export const getCostCenterList = createAsyncThunk(
  "getCostCenterList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("listCostcenter", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const createNewCostCenter = createAsyncThunk(
  "createNewCostCenter",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("costCenter", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getCostCenter = createAsyncThunk(
  "getCostCenter",
  async (_, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("getCostcenter");
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const deleteCenter = createAsyncThunk(
  "deleteCenter",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        `delCostCenter/${payload?.id}`
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const editCostCenter = createAsyncThunk(
  "editCostCenter",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        `updateCostCenter/${payload?.id}`,
        payload?.data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
const initialState = {
  isLoading: false,
  costCenterList: [],
  costCenters: {},
};

const CostCenterSlice = createSlice({
  name: "costCenter",
  initialState,
  extraReducers: (builder) => {
    builder

      .addCase(getCostCenterList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCostCenterList.fulfilled, (state, action) => {
        state.costCenterList = action.payload;
        state.isLoading = false;
      })
      .addCase(getCostCenterList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createNewCostCenter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewCostCenter.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createNewCostCenter.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getCostCenter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCostCenter.fulfilled, (state, action) => {
        state.costCenters = action.payload;
        state.isLoading = false;
      })
      .addCase(getCostCenter.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editCostCenter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editCostCenter.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.message || "Succesfully Edit");
      })
      .addCase(editCostCenter.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteCenter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCenter.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.message || "Succesfully delete");
      })
      .addCase(deleteCenter.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default CostCenterSlice.reducer;
