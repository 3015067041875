import axios from "axios";
import { BACKEND_API } from "./apiConfig";

const lang = localStorage.getItem("i18nextLng");

export default axios.create({
  baseURL: BACKEND_API,
  headers: {
    "Content-Type": "application/json",
    Accept: `application/json/${lang}`,
  },
});
