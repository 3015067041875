import { Button } from "primereact/button";
import React from "react";

export default function SharedButton({
  label = "",
  className = "",
  type = "button",
  onClick,
  icon = "",
  ...props
}) {
  return (
    <Button
      label={label}
      className={`${className} rounded-md !py-1.5 !px-2.5 bg-primary uppercase border-primary hover:border-primary !outline-none !shadow-none `}
      type={type}
      onClick={onClick}
      icon={icon}
      
      pt={{
        label: { className: "!font-semibold" },
      }}
      {...props}
    />
  );
}
