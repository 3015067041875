import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validSupergitHIS from "../../api/validSupergitHIS";
import toast from "react-hot-toast";
export const getCashFlow = createAsyncThunk(
  "getCashFlow",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("reports/cash_flow", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const getBankFlow = createAsyncThunk(
  "getBankFlow",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        "reports/bank_transfer_flow",
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getIncomeStatement = createAsyncThunk(
  "getIncomeStatement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        "reports/income_statement",
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const getLedgerList = createAsyncThunk(
  "getLedgerList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("listLedgers", payload);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const getBalaceSheet = createAsyncThunk(
  "getBalaceSheet",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        "reports/balance_sheet",
        payload
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getPatientsVATList = createAsyncThunk(
  "getPatientsVATList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        "reports/patient_vat_report",
        payload
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getCompaniesVATList = createAsyncThunk(
  "getCompaniesVATList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        "reports/company_vat_report",
        payload
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getAccountStatementList = createAsyncThunk(
  "getAccountStatementList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        "reports/getAccountStatement",
        payload
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getTrialBalanceList = createAsyncThunk(
  "getTrialBalanceList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        "reports/getTrialBalanceReport",
        payload
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);




export const getTaxReturnReportList = createAsyncThunk(
  "getTaxReturnReportList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        "reports/getTaxReturnReport",
        payload
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);



const initialState = {
  isLoading: false,
  cashFlow: [],
  bankFlow: [],
  balanceSheet: [],
  incomeStatement: [],
  ledgerList: [],
  patientsVatList: [],
  companiesVatList: [],
  accountStatementList: [],
  trialBalanceList: [],
  taxReturnReportList: []
};

const ReportSlice = createSlice({
  name: "reports",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCashFlow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCashFlow.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cashFlow = action.payload;
    });
    builder.addCase(getCashFlow.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getBankFlow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBankFlow.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bankFlow = action.payload;
    });
    builder.addCase(getBankFlow.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getBalaceSheet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBalaceSheet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.balanceSheet = action.payload;
    });
    builder.addCase(getBalaceSheet.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getIncomeStatement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getIncomeStatement.fulfilled, (state, action) => {
      state.isLoading = false;
      state.incomeStatement = action.payload;
    });
    builder.addCase(getIncomeStatement.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getLedgerList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLedgerList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ledgerList = action.payload;
    });
    builder.addCase(getLedgerList.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getPatientsVATList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPatientsVATList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.patientsVatList = action.payload;
    });
    builder.addCase(getPatientsVATList.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getCompaniesVATList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCompaniesVATList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.companiesVatList = action.payload;
    });
    builder.addCase(getCompaniesVATList.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getAccountStatementList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAccountStatementList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.accountStatementList = action.payload;
    });
    builder.addCase(getAccountStatementList.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getTrialBalanceList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTrialBalanceList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.trialBalanceList = action.payload;
    });
    builder.addCase(getTrialBalanceList.rejected, (state, action) => {
      state.isLoading = false;
    });


    builder.addCase(getTaxReturnReportList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTaxReturnReportList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.taxReturnReportList = action.payload;
    });
    builder.addCase(getTaxReturnReportList.rejected, (state, action) => {
      state.isLoading = false;
    });


  },
});
export default ReportSlice.reducer;
