import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validSupergitHIS from "../../api/validSupergitHIS";
import toast from "react-hot-toast";

export const getVendors = createAsyncThunk(
  "getVendors",
  async (_, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("getvendors");
      return response?.data?.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getVendorList = createAsyncThunk(
  "getVendorList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("list_vendor", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const CreateNewVendor = createAsyncThunk(
  "CreateNewVendor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("vendor", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getPurchasedEntryList = createAsyncThunk(
  "getPurchasedEntryList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        "list_purchased_entry",
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const CreateNewPurchasedEntry = createAsyncThunk(
  "CreateNewPurchasedEntry",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("purchased_entry", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const CreateNewPurchasedPayment = createAsyncThunk(
  "CreateNewPurchasedPayment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("purchased_payment", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);



export const getSearchedVendor = createAsyncThunk(
  "getSearchedVendor",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("searchVendor", payload);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);


export const getSearchedPurchaseEntery = createAsyncThunk(
  "getSearchedPurchaseEntery",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("searchPurchasedEntry", payload);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);


const initialState = {
  vendors: {},
  isLoading: false,
  vendorList: null,
  purchasedEntryList: null,
};

const PurchaseSlice = createSlice({
  name: "purchase",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        state.vendors = action.payload;
        state.isLoading = false;
      })
      .addCase(getVendors.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getVendorList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVendorList.fulfilled, (state, action) => {
        state.vendorList = action.payload;
        state.isLoading = false;
      })
      .addCase(getVendorList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getPurchasedEntryList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPurchasedEntryList.fulfilled, (state, action) => {
        state.purchasedEntryList = action.payload;
        state.isLoading = false;
      })
      .addCase(getPurchasedEntryList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(CreateNewVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateNewVendor.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(CreateNewVendor.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(CreateNewPurchasedEntry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateNewPurchasedEntry.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(CreateNewPurchasedEntry.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(CreateNewPurchasedPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateNewPurchasedPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action?.payload?.message);
      })
      .addCase(CreateNewPurchasedPayment.rejected, (state) => {
        state.isLoading = false;
      })



      .addCase(getSearchedVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchedVendor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vendorList = action.payload;
      })
      .addCase(getSearchedVendor.rejected, (state) => {
        state.isLoading = false;
      })

      
      .addCase(getSearchedPurchaseEntery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchedPurchaseEntery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.purchasedEntryList = action.payload;
      })
      .addCase(getSearchedPurchaseEntery.rejected, (state) => {
        state.isLoading = false;
      })


  },
});

export default PurchaseSlice.reducer;
