import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validSupergitHIS from "../../api/validSupergitHIS";
import toast from "react-hot-toast";

export const getCustomers = createAsyncThunk(
  "getCustomers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("getCustomers");
      return response?.data?.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getCustomersList = createAsyncThunk(
  "getCustomersList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("listCustomers", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const CreateCustomers = createAsyncThunk(
  "CreateCustomers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("customer", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getSearchedCustomer = createAsyncThunk(
  "getSearchedCustomer",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("searchCustomer", payload);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const deleteCustomer = createAsyncThunk(
  "deleteCustomer",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        `delCustomers/${payload?.id}`
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const editCustomer = createAsyncThunk(
  "editCustomer",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        `updateCustomers/${payload?.id}`,
        payload?.data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
const initialState = {
  customers: {},
  isLoading: false,
  customersList: [],
  // searchedCustomer: null,
};

const CustomerSlice = createSlice({
  name: "customers",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(CreateCustomers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(CreateCustomers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCustomers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.customers = action.payload;
        state.isLoading = false;
      })
      .addCase(getCustomers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCustomersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomersList.fulfilled, (state, action) => {
        state.customersList = action.payload;
        state.isLoading = false;
      })
      .addCase(getCustomersList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getSearchedCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchedCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customersList = action.payload;
      })
      .addCase(getSearchedCustomer.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.message || "Succesfully Edit");
      })
      .addCase(editCustomer.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.message || "Succesfully delete");
      })
      .addCase(deleteCustomer.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default CustomerSlice.reducer;
