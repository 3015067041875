import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import validSupergitHIS from "../../api/validSupergitHIS";

export const CreatePaymentVoucherApi = createAsyncThunk(
  "payment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        "paymentVouchers/create",
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const CreateReceiptVoucherApi = createAsyncThunk(
  "CreateReceiptVoucherApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        "receiptVouchers/create",
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const getCashAccounts = createAsyncThunk(
  "getCashAccounts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("getCashAccounts");
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const getPaymentVoucher = createAsyncThunk(
  "getPaymentVoucher",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("paymentVouchers", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const getReceiptVoucher = createAsyncThunk(
  "getReceiptVoucher",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("receiptVouchers", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const getBankAccounts = createAsyncThunk(
  "getBankAccounts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("getBankAccounts");
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const deletePaymentVoucher = createAsyncThunk(
  "deletePaymentVoucher",
  async (id, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(`paymentVouchers/${id}`);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const deleteReceiptVoucher = createAsyncThunk(
  "deleteReceiptVoucher",
  async (id, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(`receiptVouchers/${id}`);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const updatePaymentVoucher = createAsyncThunk(
  "updatePaymentVoucher",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        `paymentVoucher/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const updateReceiptVoucher = createAsyncThunk(
  "updateReceiptVoucher",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        `receiptVoucher/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

const initialState = {
  isLoading: false,
  paymentVouchers: null,
  receiptVouchers: null,
  Accounts: null,
};
const VoucherSlice = createSlice({
  name: "Voucher",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(CreatePaymentVoucherApi.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(CreatePaymentVoucherApi.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.isLoading = false;
      })
      .addCase(CreatePaymentVoucherApi.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(CreateReceiptVoucherApi.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(CreateReceiptVoucherApi.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.isLoading = false;
      })
      .addCase(CreateReceiptVoucherApi.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getBankAccounts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBankAccounts.fulfilled, (state, action) => {
        state.Accounts = action.payload;
        state.isLoading = false;
      })
      .addCase(getBankAccounts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCashAccounts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCashAccounts.fulfilled, (state, action) => {
        state.Accounts = action.payload;
        state.isLoading = false;
      })
      .addCase(getCashAccounts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPaymentVoucher.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPaymentVoucher.fulfilled, (state, action) => {
        state.paymentVouchers = action.payload;
        state.isLoading = false;
      })
      .addCase(getPaymentVoucher.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getReceiptVoucher.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getReceiptVoucher.fulfilled, (state, action) => {
        state.receiptVouchers = action.payload;
        state.isLoading = false;
      })
      .addCase(getReceiptVoucher.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deletePaymentVoucher.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deletePaymentVoucher.fulfilled, (state, action) => {
        state.isLoading = false;
        toast?.success(action?.payload?.message);
      })
      .addCase(deletePaymentVoucher.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteReceiptVoucher.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteReceiptVoucher.fulfilled, (state, action) => {
        state.isLoading = false;
        toast?.success(action?.payload?.message);
      })
      .addCase(deleteReceiptVoucher.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateReceiptVoucher.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateReceiptVoucher.fulfilled, (state, action) => {
        state.isLoading = false;
        toast?.success(action?.payload?.message);
      })
      .addCase(updateReceiptVoucher.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updatePaymentVoucher.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatePaymentVoucher.fulfilled, (state, action) => {
        state.isLoading = false;
        toast?.success(action?.payload?.message);
      })
      .addCase(updatePaymentVoucher.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export default VoucherSlice.reducer;
