import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validSupergitHIS from "../../api/validSupergitHIS";
import toast from "react-hot-toast";

export const getEmployeesList = createAsyncThunk(
  "getEmployeesList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("employees/list", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const CreateEmployees = createAsyncThunk(
  "CreateEmployees",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("employees", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
 
export const UpdateEmployees = createAsyncThunk(
  "UpdateEmployees",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("employees/update", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
 
export const getAllEmployees = createAsyncThunk(
  "getAllEmployees",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("getEmployees", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

const initialState = {
  allEmployees: {},
  isLoading: false,
  employeesList: null,
};

const EmployeeSlice = createSlice({
  name: "employees",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeesList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmployeesList.fulfilled, (state, action) => {
        state.employeesList = action.payload;
        state.isLoading = false;
      })
      .addCase(getEmployeesList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(CreateEmployees.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateEmployees.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(CreateEmployees.rejected, (state) => {
        state.isLoading = false;
      })

 
      .addCase(UpdateEmployees.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdateEmployees.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(UpdateEmployees.rejected, (state) => {
        state.isLoading = false;
      })

 
      .addCase(getAllEmployees.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllEmployees.fulfilled, (state, action) => {
        state.allEmployees = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllEmployees.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default EmployeeSlice.reducer;

// toast.success(action.payload.data || "Succesfull");
