import React, { useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';

const ErrorBoundaryAlert = () => {
  useEffect(() => {
    toast.error('Error Occurred! Please be patient!', {
      duration: 3000,
      position: 'bottom-center'
    });
  }, []);

  return <Toaster />;
};

export default ErrorBoundaryAlert;
