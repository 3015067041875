import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validSupergitHIS from "../../api/validSupergitHIS";
import toast from "react-hot-toast";

export const getLeavesList = createAsyncThunk(
  "getLeavesList",
  async (data, { rejectWithValue, getState }) => {
    try {
      const response = await validSupergitHIS.post("leaves/list", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      if (error.response && error.response.status === 401) {
        const state = getState();
        state.auth.token = null;
        state.auth.user = null;
      }
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const CreateNewLeave = createAsyncThunk(
  "CreateNewLeave",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("leaves", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);


export const CreateNewAttendance = createAsyncThunk(
  "CreateNewAttendance",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("attendances", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getAttendanceList = createAsyncThunk(
  "getAttendanceList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("attendances/list", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);


export const CreateNewDeduction = createAsyncThunk(
  "CreateNewDeduction",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("deductions", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getDeductionList = createAsyncThunk(
  "getDeductionList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("deductions/list", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);


export const CreateNewAllowance = createAsyncThunk(
  "CreateNewAllowance",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("allowances", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getAllowanceList = createAsyncThunk(
  "getAllowanceList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("allowances/list", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);


const initialState = {
  isLoading: false,
  leavesList: [],
  attendanceList: [],
  deductionList: [],
  allowanceList: [],
};

const HrSlice = createSlice({
  name: "hr",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(CreateNewLeave.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateNewLeave.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(CreateNewLeave.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(CreateNewAttendance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateNewAttendance.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(CreateNewAttendance.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(CreateNewDeduction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateNewDeduction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(CreateNewDeduction.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(CreateNewAllowance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateNewAllowance.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(CreateNewAllowance.rejected, (state) => {
        state.isLoading = false;
      })


      .addCase(getLeavesList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLeavesList.fulfilled, (state, action) => {
        state.leavesList = action.payload;
        state.isLoading = false;
      })
      .addCase(getLeavesList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getAttendanceList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAttendanceList.fulfilled, (state, action) => {
        state.attendanceList = action.payload;
        state.isLoading = false;
      })
      .addCase(getAttendanceList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getDeductionList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDeductionList.fulfilled, (state, action) => {
        state.deductionList = action.payload;
        state.isLoading = false;
      })
      .addCase(getDeductionList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getAllowanceList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllowanceList.fulfilled, (state, action) => {
        state.allowanceList = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllowanceList.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default HrSlice.reducer;
